import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import { Container, Row, Col } from "react-bootstrap";
// Images
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../../components/seo";

const BikeRacks = () => {
  return (
    <Layout>
      <Seo
        title="Aparcabicicletas - BIKESAFE"
        description="Nuestra amplia gama de racks y soportes para aparcar bicicletas puede acomodar cualquier número de bicicletas; desde una hasta 24 en un único rack. Todos nuestros racks y soportes son resistentes a la oxidación, y no solo desafiarán las condiciones climatológicas adversas, sino también las fechorías de los ladrones."
      />
      <Container>
        <Row>
          <Col sm="12">
            <h1 className="mt-5 mb-5 text-center uppercase">
            Aparcabicicletas
            </h1>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <p className="flow-text">
            Nuestra amplia gama de racks y soportes para aparcar bicicletas puede acomodar cualquier número de bicicletas; desde una hasta 24 en un único rack. Todos nuestros racks y soportes son resistentes a la oxidación, y no solo desafiarán las condiciones climatológicas adversas, sino también las fechorías de los ladrones.
            </p>
            <p className="flow-text">Consulte nuestra selecta gama a continuación para solucionar sus requisitos de seguridad:</p>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <hr />
          <Col sm="12" lg="6" className="p-2">
            <Link to="/product/61">
              <StaticImage placeholder="blurred" className="fluid" src="../product/61/homepage-61.jpg"></StaticImage>
              <h3 className="uppercase mt-3">Aparcabici de dos niveles</h3>
              <p>¡Ahorro de espacio, magnífica utilización y excelente relación calidad-precio! El rack para bicicletas de dos alturas es un sistema de aparcamiento de bicicletas innovador y muy intuitivo que le permite...</p>
              <p className="shop-now">Solicite una cotización</p>
            </Link>
          </Col>
          <Col sm="12" lg="6" className="p-2">
            <Link to="/product/230">
              <StaticImage placeholder="blurred" className="fluid" src="../product/230/homepage-230.jpg"></StaticImage>
              <h3 className="uppercase mt-3">BikeSafe Aparcabici de dos niveles</h3>
              <p>El portabicicletas de dos niveles BikeSafe es un sistema de estacionamiento de bicicletas pionero y fácil de usar, con un excelente almacenamiento de bicicletas que ahorra espacio, excelente facilidad de uso...</p>
              <p className="shop-now">Solicite una cotización</p>
            </Link>
          </Col>
          <Col sm="12" lg="6" className="p-2">
            <Link to="/product/80">
              <StaticImage placeholder="blurred" className="fluid" src="../product/80/homepage-80.jpg"></StaticImage>
              <h3 className="uppercase mt-3">Aparcabici de pared independiente</h3>
              <p>Una variación de nuestro popular aparcabici de pared. Este modelo independiente permite que los usuarios saquen el máximo provecho del la superficie del suelo. La estructura se puede fijar con tornillos...</p>
              <p className="shop-now">Solicite una cotización</p>
            </Link>
          </Col>
          <Col sm="12" lg="6" className="p-2">
            <Link to="/product/88">
              <StaticImage placeholder="blurred" className="fluid" src="../product/88/homepage-88.jpg"></StaticImage>
              <h3 className="uppercase mt-3">Aparcabici semivertical</h3>
              <p>Para aprovechar al máximo el espacio cuando escasea. El aparcabici semivertical se puede utilizar al interior o exterior. La rueda trasera se puede candar al rack con un candado tipo D o una cadena...</p>
              <p className="shop-now">Solicite una cotización</p>
            </Link>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default BikeRacks;